/*------------------TABLE MODELES DE PROJET----------------------*/
.p-treetable table{
	table-layout: auto !important;
}

.p-treetable .p-treetable-thead > tr > th:last-child, .p-treetable .p-treetable-tbody > tr > td:last-child{
	background:transparent;
	border-color:transparent;
}

.p-treetable .p-treetable-tbody > tr > td:first-child button{
	float:right;
	background:transparent;
	border-color:transparent;
	cursor:pointer;
}

.p-treetable .p-treetable-tbody > tr > td:first-child button:hover{
	color:#03A9F4;
}

.p-treetable .p-treetable-tbody > tr > td:first-child button:first-child{
	margin-top:5px;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler{margin-right:10px;}
a .p-treetable-toggler .p-unselectable-text{margin-left:0px !important; display: none;}

.p-treetable a{
	color: var(--cyan-blue);
	text-decoration: none !important;
  }
  .p-treetable table{
	font-size:var(--font-size);
  }
  .p-treetable .p-paginator-top {
	border-radius: 0;
  }
  .p-treetable .p-paginator-bottom {
	border-radius: 0;
  }
  .p-treetable .p-treetable-header {
	background: #efefef;
	color: #212529;
	border: solid var(--gray-200);
	padding: 0.8rem;
	font-weight: 600;
  }
  .p-treetable .p-treetable-footer {
	background: #efefef;
	color: #212529;
	border: 1px solid var(--gray-200);
	padding: var(--font-size) var(--font-size);
	font-weight: 600;
  }
  .p-treetable .p-treetable-thead > tr > th {
	text-align: left;
	padding: var(--font-size) var(--font-size);
	border: 1px solid var(--gray-200);
	font-weight: 600;
	color: #212529;
	background: var(--gray-100); 
	transition: box-shadow 0.15s;
  }
  .p-treetable .p-treetable-tfoot > tr > td {
	text-align: left;
	padding: var(--font-size) var(--font-size);
	border: 1px solid var(--gray-20);
	font-weight: 600;
	color: #212529;
	background: var(--white);
  }
  .p-treetable .p-sortable-column {
	outline-color: rgba(38, 143, 255, 0.5);
  }
  .p-treetable .p-sortable-column .p-sortable-column-icon {
	color: #6c757d;
	margin-left: 0.5rem;
  }
  .p-treetable .p-sortable-column .p-sortable-column-badge {
	border-radius: 50%;
	height: 1.143rem;
	min-width: 1.143rem;
	line-height: 1.143rem;
	color: var(--white);
	background: var(--cyan-blue);
	margin-left: 0.5rem;
  }
  .p-treetable .p-sortable-column:not(.p-highlight):hover {
	background: #e9ecef;
	color: #212529;
  }
  .p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
	color: #6c757d;
  }
  .p-treetable .p-sortable-column.p-highlight {
	background: var(--white);
	color: var(--cyan-blue);
  }
  .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
	color: var(--cyan-blue);
  }
  .p-treetable .p-treetable-tbody > tr {
	background: var(--white);
	color: var(--gray-800);
	transition: box-shadow 0.15s;
	outline-color: rgba(38, 143, 255, 0.5);
  }
  .p-treetable .p-treetable-tbody > tr > td {
	text-align: center;
	border: 1px solid var(--gray-200);
	padding: 0.5rem;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
	width: 2rem;
	height: 2rem;
	color: #6c757d;
	border: 0 none;
	background: transparent;
	border-radius: 50%;
	transition: box-shadow 0.15s;
	margin-right: 0.5rem;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
	color: #495057;
	border-color: transparent;
	background: transparent;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  }
  .p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
	margin-right: 0.5rem;
  }
  .p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {
	color: #212529;
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight {
	background: var(--cyan-blue);
	color: var(--white);
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
	color: var(--white);
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
	color: var(--white);
  }
  .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
	background: #e9ecef;
	color: #212529;
  }
  .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
	color: #212529;
  }
  .p-treetable .p-column-resizer-helper {
	background: var(--cyan-blue);
  }
  .p-treetable .p-treetable-scrollable-header,
  .p-treetable .p-treetable-scrollable-footer {
	background: #efefef;
  }
  .p-treetable .p-treetable-loading-icon {
	font-size: 2rem;
  }
  .p-treetable.p-treetable-gridlines .p-datatable-header {
	border-width: 1px 1px 0 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-footer {
	border-width: 0 1px 1px 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-top {
	border-width: 0 1px 0 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-bottom {
	border-width: 0 1px 1px 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
	border-width: 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
	border-width: 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
	border-width: 1px;
  }
  .p-treetable.p-treetable-sm .p-treetable-header {
	padding: 0.875rem 0.875rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
	padding: 0.5rem 0.5rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
	padding: 0.5rem 0.5rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
	padding: 0.5rem 0.5rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-footer {
	padding: 0.5rem 0.5rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-header {
	padding: 1.25rem 1.25rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
	padding: 1.25rem 1.25rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
	padding: 1.25rem 1.25rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
	padding: 1.25rem 1.25rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-footer {
	padding: 1.25rem 1.25rem;
  }

.phaseModel { 
	background:#03A9F4 !important;
	border-color:transparent !important;
	border-bottom:1px solid #fff !important;
	text-transform:uppercase;
	color:#fff;
	font-weight:700;
	font-size:0.9rem;
 }

 .phaseModel a{
	 color:#fff !important;
 }

 .phaseModel input{
	background:transparent;
	text-transform:uppercase;
	color:#fff;
	font-weight:700;
	font-size:12px;
	border-color:#fff;
	width:95%;
	float:right;
 }

.dateDepassee{ background: rgba(255, 105, 97, 0.4) !important; }
.dateTerminee{ background: rgba(195, 237, 189, 0.6) !important; }