/*-------------------- CSS USER -------------------*/
.module-item {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    text-transform: none;
    text-align: left;
    color: var(--Gauche-Texte);
    background: var(--Gauche-Fond);
    border-bottom: 1px solid var(--Gauche-Bordure);
    border-right: 1px solid var(--Gauche-Bordure);
    font-weight: 700;
    padding: 10px 5px 10px 25px;

    &:hover {
        background: var(--Gauche-Fond-Hover);
        cursor: pointer;
        color: var(--Gauche-Texte-Hover);
    }

    &.active {
        background: var(--Gauche-Fond-Hover);
        color: var(--Gauche-Texte-Hover);
        border-top: 1px solid var(--Gauche-Bordure);
        margin-top: -1px;
    }
}

.application-item {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 15px;
    padding: 10px 5px;
    color: var(--Gauche-Texte);
    background: var(--Gauche-Fond);
    border: 1px solid var(--Gauche-Bordure);
    border-top-color: transparent;
    transition: 0.15s ease-in-out;

    &.active {
        background: var(--Gauche-Fond-Hover);
        padding: 10px 5px 10px 10px;
        color: var(--Gauche-Texte-Hover);
        border-right: 1px solid var(--Gauche-Bordure);
        border-left: 8px solid var(--Gauche-Texte-Hover);
    }

    &:hover {
        background: var(--Gauche-Fond-Hover);
        cursor: pointer;
        height: 100%;
        color: var(--Gauche-Texte-Hover);
        padding: 10px 5px 10px 10px;
        border-left: 8px solid var(--Gauche-Texte-Hover);
    }
}

.portail-item {
    color: var(--Gauche-Texte);
    font-size: 15px;
    background: var(--Gauche-Fond);
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    border: 1px solid var(--Gauche-Bordure);
    padding: 10px;
    cursor:pointer;
}

/*-------------------- CSS ADMIN -------------------*/
.module-item-admin {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    text-transform: none;
    text-align: left;
    color: #E5E5E5;
    background: #2D84AF;
    border-bottom: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    font-weight: 700;
    padding: 10px 5px 10px 25px;

    &:hover {
        background: #65B1D7;
        cursor: pointer;
        color: #fff;
    }

    &.active {
        background: #65B1D7;
        color: #fff;
        border-top: 1px solid #e3e3e3;
        margin-top: -1px;
    }
}

.application-item-admin {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 15px;
    padding: 10px 5px;
    color: #e5e5e5;
    background: #2D84AF;
    border: 1px solid #e3e3e3;
    border-top-color: transparent;
    transition: 0.15s ease-in-out;

    &.active {
        background: #65B1D7;
        padding: 10px 5px 10px 10px;
        color: #fff;
        border-right: 1px solid #e3e3e3;
        border-left: 8px solid #fff;
    }

    &:hover {
        background: #65B1D7;
        cursor: pointer;
        height: 100%;
        color: #fff;
        padding: 10px 5px 10px 10px;
        border-left: 8px solid #fff;
    }
}

.portail-item-admin {
    color: #e5e5e5;
    font-size: 15px;
    background: #2D84AF;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    border: 1px solid #e3e3e3;
    padding: 10px;
    cursor:pointer;
}
