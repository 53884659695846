/*-------- CONFIG POP-UP --------*/
.sesame-clockpicker .clock-picker__overlay {
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
}
.sesame-clockpicker .clock-picker__wrapper {
    z-index: 1;
    border-radius: 5px;
}

/*-------- HEADER --------*/
.sesame-clockpicker .clock-picker__nav {
    margin-bottom: 35px;
}
.sesame-clockpicker .clock-picker__time-display__button--selected,
.sesame-clockpicker .clock-picker__time-display__button:hover {
    color: var(--cyan-blue);
}

/*-------- FOOTER --------*/
.sesame-clockpicker .clock-picker__footer {
    margin-top: 35px;
}

/*-------- BUTTONS --------*/
.sesame-clockpicker .clock-picker__footer__dialog-control-button{
    background-color: var(--green);
	border: none;
	border-radius: 5px;
    padding: 10px;
	font-size: 12px;
	letter-spacing: 1px;
	font-weight: 700;
	text-transform: uppercase;
	color: var(--white);
	border-radius: 5px;
}
.sesame-clockpicker .clock-picker__footer__dialog-control-button:first-child{
    background-color: var(--orange);
    margin-right:5px;
}

/*-------- CLOCK SIZE --------*/
.sesame-clockpicker .clock-picker__circle--hours, 
.sesame-clockpicker .clock-picker__circle--minutes{
    width:265px;
    height:265px;
}

/*-------- SELECTOR CIRCLE HOUR --------*/
.sesame-clockpicker .clock-picker__item-button {
    width: 80%;
    height: 80%;
    transition: all 0.2s ease-out;
}
.sesame-clockpicker .clock-picker__item-button--selected {
    background-color: var(--cyan-blue);
    color: white;
}
.sesame-clockpicker .clock-picker__item-button--selected:hover {
    background-color: var(--cyan-blue) !important;
    color: white;
}
.sesame-clockpicker .clock-picker__item-button:hover{
    background-color: rgba(3, 169, 244, 0.2);
}

/*-------- CENTER --------*/
.sesame-clockpicker .clock-picker__clock-face .clock-picker__clock-face__center {
    fill: var(--cyan-blue);
}
.sesame-clockpicker .clock-picker__clock-face__tick--selected{
    stroke: var(--cyan-blue) !important;
}