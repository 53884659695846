/* 
  You can add global styles to this file, and also import other style files 
  COPIER COLLER DU SHELL (v0.3.2)
*/
@import 'assets/styles/general.scss';
@import 'assets/styles/theme.scss';
@import 'assets/styles/header.scss';
@import 'assets/styles/sidebar.scss';
@import 'assets/styles/buttons.scss';
@import 'assets/styles/project-table.scss';
@import 'assets/styles/tableau.scss';
@import 'assets/styles/drag-and-drop.scss';
@import 'assets/styles/confirm-dialog.scss';
@import 'assets/styles/pop-up.scss';
@import 'assets/styles/clockpicker.scss';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~primeicons/primeicons.css';
@import '~primeng/resources/primeng.min.css';
@import '~primeflex/primeflex.css';
@import '~quill/dist/quill.snow.css';
@import '~quill/dist/quill.core.css';

//Font de l'accueil
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/dosis/v27/HhyJU5sn9vOmLxNkIwRSjTVNWLEJN7Ml2xME.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.container{
  padding-top: 0.5% !important;
  padding-right: 0.5% !important;
  padding-bottom: 1% !important;
  padding-left: 0.5% !important;
}

button {
  padding : 10px 25px; 
  margin: 20px;
  font-size: 1.1em;
  font-weight: 600;
}

.rond {
  border-radius: 20px;
  padding: 10px;
  width: 40px;
  height: 40px;
}

.orange {
  color: white;
}

.grey {
  color: #0d436e;
}

.titre-parametrage {
  color : var(--blue);
  text-transform: uppercase;
  font-size: 1.3rem;
}

/* Listes déroulantes de territoires ou d'établissements */
#territoire-etab {
  border-radius: 25px;

  .p-dropdown {
      background-color: #1e6187 !important;
      border-radius: 25px;

      .p-dropdown-label {
          color: var(--white);
          font-size: 1rem;
          font-weight: 600;
      }

      .p-dropdown-trigger-icon {
          color: var(--white);
      }

      .p-dropdown-panel {
          margin-left: -15px !important;
      }
  }
}

/** MEGA MENU DE  TDB ET PARAM **/
/* Menu de gauche */
.p-megamenu{
  background-color: var(--white);
}

.p-megamenu-vertical{
  width: 100%!important;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem:hover {
  background-color: var(--gray-200);
}

.p-megamenu .p-megamenu-root-list > .p-menuitem:hover > .p-menuitem-link .p-menuitem-text {
  font-weight: 600;
  color: var(--cyan-blue)!important;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem:hover > .p-menuitem-link .p-submenu-icon {
  font-weight: 600;
  color: var(--cyan-blue)!important;
}

.p-menuitem:hover > .p-megamenu-panel{
  display: block;
  z-index: 1001;
}

/* Pour les sous menus, duplication nécessaire car styleClass appliqué sur le rôle */
.menuItem {
  color: var(--cyan-blue)!important;
  font-weight: bold!important;
}

//Permet d'avoir la couleur de menuItem
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text{
  color: inherit;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text, .p-megamenu .p-megamenu-root-list 
  > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: inherit;
}

//PARAM cartes
.card-container{
  margin-top: 20px; 
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

//CSS lié aux cartes
.card {
  width: 18%;
  height: 40vh;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 2%;
}

.ajout{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8rem;
  background-color: rgb(219, 240, 224);
  color: rgb(167,218,179);
  cursor: pointer;
}

//HEADER DE LA CARTE
.card-header{
  height: 4vh;
  max-height: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--gray-100);
  
  h4{
      background-color: transparent;
      text-transform:capitalize!important; 
      letter-spacing: 0!important;
      margin: 0 15px 0 15px!important;
      padding: 0!important;
      font-size: medium!important;
      overflow: hidden;
      white-space: nowrap;
      text-overflow:ellipsis;
      cursor: pointer;
  }

  input{
    width: 100%!important;
  }

  .not-editable{
    cursor: default!important;
  }


  .delete-card{
      cursor: pointer;
      color: var(--white);
      background-color: var(--tab-red);
      height: 100%;
      width: 12.5%;
      min-width: 12.5%;
      display: flex;
      align-items: center;
      justify-content: center;
  }
}

//contenu de la carte
.card-content {
  height: 85%;
  overflow-x: unset;
}

.content-list {
    display: block;
    margin-top: 10px;
    overflow-y: auto;

    .chip-grey {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .full-width, .cdk-drag {
        width: 90%;
    }
}

.card-grey{
  border: 1px solid var(--gray-100);
}

.square{
  border-radius: 0%!important;
}

.green{
  background-color:var(--green);
  color:var(--white);
}

#pinkCard > .p-card.p-component{
  background: #F7F0FF !important;
  .p-card-title{
    color: #BD8EF3;
  }
}

#orangeCard > .p-card.p-component{
  background: #FFFAF0 !important;
  .p-card-title{
    color: #FFB524;
  }
}

.chip-grey {
  border-radius: 10px;
  padding: 5px;
  background-color: var(--gray-100);
  width: 100%;
  height: 43px;
  margin: 5px;
  font-size: small;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ajout-element {
  min-height: 4vh;
  max-height: 8vh;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;

  .btn-amc {
      float:none;
  }

  .green {
      font-size: medium;
      margin: 0!important;
      width: 100%;
      height: 100%;
      span{
          display: flex;
          justify-content: center;
          align-items: center;
      }
  }

  input {
      width: 75%;
      height: 100%;
      border-radius: 0px;
  }

  input:focus {
      outline:none !important;
      outline-width: 0 !important;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
  }
}

.elements {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.fa-circle-xmark {
  cursor: pointer;
}