.FondVert {
    background-color: #D0EED1;
    color: #63a366;
}

.FondOrange {
    background-color: #f3e0ae;
    color:#CB7230;
}

.FondBleu {
    background-color: #d2e8f8;
    color: var(--cyan-blue);
}

.FondRouge {
    background-color: #FFC3C0;
    color: #c23a33;
}

.FondJaune {
    background-color: #f5f6ba;
    color:#b99e00
}

.FondViolet {
    background-color: #e8dbf6;
    color: #b37eec;
}