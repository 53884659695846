/*------------------Pop-up confirmation Prime NG----------------------*/
.ui-dialog-titlebar-icon span:hover{
	color:var(--red);
}

.ui-dialog-footer button:first-child{
	background-color: var(--green);
	border:1px solid var(--dark-green);
	border-radius:5px;
}

.ui-dialog-footer button:nth-child(2){
	background-color: var(--orange);
	border:1px solid var(--dark-orange);
	border-radius:5px;
}

.ui-dialog-footer button:first-child:hover{
	background-color: var(--green);
	border:1px solid var(--dark-green);
}

.ui-dialog-footer button:nth-child(2):hover{
	background-color: var(--orange);
	border:1px solid var(--dark-orange);
}

.ui-dialog-footer button:enabled:focus{
	box-shadow:0 0 0 0 transparent;
}