html {
    height: 100%;
    margin: 0;
    padding: 0;
}

form {
    height: 100%;
    font-size: 14px;
    margin: 0;
    padding: 0;
}

body {
    margin: 0px;
    padding: 0px;
    height: 100%;
    width: 100%;
    font-family: Arial;
    color: #666666;
    font-size: 14px;
    background: #ffffff;
}

/* -------------------------------------- SCROLLBAR ----------------------------------------------*/
/* Optimisation Chrome, Edge, Safari */

*::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }
  
  *::-webkit-scrollbar-track {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #f1f1f1; /* color of the tracking area */
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #c1c1c1; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid #f1f1f1; /* creates padding around scroll thumb */
  }
  
  /* Optimisation Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #f1f1f1;
  }
  
  /* -------------------------------------- FIN SCROLLBAR ----------------------------------------------*/