/*----------------------------------------------------- Mise en forme boutons -----------------------------------------------------*/
.btn-amc {
	padding: 10px;
	font-size: 12px;
	letter-spacing: 1px;
	font-weight: 700;
	text-transform: uppercase;
	color: var(--white);
	float: right;
	border-radius: 5px !important;
	margin-top: 15px;
	margin-bottom: 15px;
	cursor: pointer;
}

.btn-amc:disabled,
.btn-amc[disabled] {
	opacity: 0.2;
	cursor: not-allowed;
}

.btn-amc.medium.screen{
	background-color: #c49be4;
	border: 1px solid #c49be4;
  }

.min {
	font-size: 11px;
	padding: 5px 10px;
	margin-top: 0px;
	margin-bottom: 0px;
}

.small {
	padding: 0px;
	width: 25px;
	height: 25px;
	font-size: 15px !important;
	margin-right: 10px;
	margin-top: 0px;
	margin-bottom: 0px;
}

.small:focus {
	border: 1px solid var(--dark-cyan-blue);
	outline-style: none;
	box-shadow: 0px 0px 5px var(--dark-cyan-blue);
}

.medium {
	padding: 5px;
	width: 38px;
	height: 38px;
	font-size: 15px;
	margin-right: 10px;
	margin-top: 0px;
	margin-bottom: 0px;
}

.xsmall {
	padding: 0px;
	width: 17px;
	height: 17px;
	font-size: 10px;
	margin-top: 0px;
	margin-bottom: 0px;
}

//Bouton à choix multiple
.multibutton .p-splitbutton .p-splitbutton-defaultbutton {
	display: none;
}
.multibutton .p-fluid .p-button-icon-only {
	position: absolute;
	width: 25px;
	border: 1px solid var(--dark-cyan-blue);
}
.multibutton .p-fluid .p-button-icon-only:hover {
	background: var(--dark-cyan-blue);
}
.multibutton .p-fluid .p-button-icon-only:focus {
	border: 1px solid var(--dark-cyan-blue);
	outline-style: none;
	box-shadow: 0px 0px 5px var(--dark-cyan-blue);
}
.multibutton .pi-chevron-down:before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f067";
}
.multibutton .p-splitbutton .p-menu {
	margin-top: 10px;
	text-transform: none;
}
.multibutton .p-splitbutton-menubutton {
	height: 25px;
	width: 25px;
	border-bottom-left-radius: 5px !important;
	border-top-left-radius: 5px !important;
}

.droits .p-splitbutton .p-menu {
	margin-left: -100%;
}

/*----------------------------------------------------- Couleurs -----------------------------------------------------*/

.green {
	background-color: var(--green);
	border: none;
	border-radius: 5px;
}

.green:hover {
	background-color: var(--dark-green);
}

.orange {
	background-color: var(--orange);
	border: none;
	border-radius: 5px;
}

.orange:hover {
	background-color: var(--dark-orange);
}

.red {
	background-color: var(--red);
	border: none;
	border-radius: 5px;
}

.red:hover {
	background-color: var(--dark-red);
}

.rose {
	background: var(--rose);
	border: none;
	border-radius: 5px;
	color: var(--white);
}

.rose:hover {
	background-color: var(--dark-rose);
}

.blue {
	background-color: var(--cyan-blue);
	border: none;
	border-radius: 5px;
}

.blue:hover {
	background-color: var(--dark-cyan-blue);
}

.grey {
	background-color: var(--gray-200);
	border: none;
	border-radius: 5px;
	color: var(--gray-700);
}

.grey:hover {
	background-color: var(--gray-500);
}

/*----------------------------------------------------- Icônes -----------------------------------------------------*/

// Icône valider (check)
.icon-validate::before {
	font-family: "Font Awesome 5 Free"; /*Free for Regular & Solid - Pro for Regular & Solid Pro License - Brand for Brands symbols*/
	font-weight: 900; /*400 for Regular & Brands - 900 for Solid - 300 for Light*/
	content: "\f00c";
}

// Icône flèche gauche (arrow-left)
.icon-cancel::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f060";
}

// Icône stylo (pen)
.icon-modify::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f303";
}

// Icône plus (plus)
.icon-add::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f067";
}

// Icône croix (times)
.icon-delete::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f00d";
}

// Icône loupe (search)
.icon-search::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f002";
}

// Icône 4 flèches (arrows-alt)
.icon-move::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f0b2";
}

// Icône double fichier (copy)
.icon-copy::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f0c5";
}

// Icône utilisateur avec un + (user-plus)
.icon-suppleant::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f234";
}

// Icône de tri double flèche (sort)
.icon-order::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f0dc";
}

// Icône flèche vers la gauche (arrow-left)
.icon-return::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f060";
}

// Icône 3 utilisateurs (users)
.icon-permission::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f0c0";
}

// Icône 3 barres avec décalage (stream)
.icon-planning::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f550";
}

// Icône tableau presse-papiers (clipboard)
.icon-note::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f328";
}

// Icône € (euro-sign)
.icon-euro::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f153";
}

// Icône 2 bulles discussion (comments)
.icon-comment::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f086";
}

// Icône poubelle (trash-alt) - version rouge
.icon-trash::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f2ed";
	color: var(--dark-red);
}

// Icône poubelle (trash-alt) - version blanche
.icon-trash2::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f2ed";
	color: var(--white);
}

// Icône triangle avec ! (exclamation-triangle)
.icon-warning::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f071";
}

// Icône i (info)
.icon-info::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f129";
}

// Icône flèche sortante d'un carré (share-square)
.icon-export::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f14d";
}

// Icône liste à points (list)
.icon-stream::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f03a";
}

// Icône trombone (paperclip)
.icon-paperclip::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f0c6";
}

.icon-deliverable::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\004c";
}

.icon-initfile::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\0049";
}

// Icône flèche entrant dans un carré (sign-in-alt)
.icon-login::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f2f6";
}

// Icône flèche sortante d'un carré (sign-out-alt)
.icon-logout::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f2f5";
}

// Icône cadenas déverrouillé (lock-open)
.icon-unlock::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f3c1";
}

// Icône cadenas (lock)
.icon-lock::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f023";
}

// Icône balais (broom)
.icon-broom::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f51a";
}

// Icône windows (windows)
.icon-windows::before {
	font-family: "Font Awesome 5 Brands";
	font-weight: 400;
	content: "\f17a";
}

// Icône oeil (eye)
.icon-eye::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f06e";
}

// Icône echange (exchange-alt)
.icon-exchange-alt::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f362";
}

// Icône flèche droite (arrow-right)
.icon-arrow-right::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f061";
}

// Icône horloge (clock)
.icon-clock::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f017";
}

// Icône calendrier (calendar)
.icon-calendar::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f133";
}

// Icône validé rond (check-circle)
.icon-check-circle::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f058";
}

// Icône croix rond (times-circle)
.icon-times-circle::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f057";
}

// Icône panneau carte (map-signs)
.icon-map-signs::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f277";
}

// Icône mégaphone (bullhorn)
.icon-bullhorn::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f0a1";
  }
  
