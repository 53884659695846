	.inputfile{
		color:var(--white);
		background:var(--cyan-blue);
		border: 1px solid var(--dark-cyan-blue);
		padding: 0.5rem 1rem;
		font-size: 0.9rem;
		margin-top:1rem;
		margin-bottom:1rem;
		border-radius:5px;
		text-align:center;
		font-weight:700;
		font-family: var(--font-family);
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	}

	.inputfile:hover, .inputfile:focus{
		background: var(--dark-cyan-blue);
		border-color:transparent;
	}

	.dragdrop{
		border:3px dashed var(--cyan-blue);
		margin:auto;
		margin-left:10px;
		display:block;
		text-align:center;
		padding:1rem;
	}

	.dragdrop b:before{
		display:block;
		text-align:center;
		font-family: "Font Awesome 5 Free"; 
		font-weight:900; 
		font-size:60px;
		color:var(--cyan-blue);
		content: "\f382";
	}

	.dragdrop i{
		font-size:11px;
		text-transform:uppercase;
		color:var(--dark-cyan-blue);
		font-style:normal;
		font-weight:900;
		background:var(--white);
		padding:0px 15px;
	}

	.dragdrop hr{
		border-top:2px solid var(--dark-cyan-blue);;
		width:90%;
		margin-bottom:-12px;
	}

	.LimitAlert{
		color:var(--dark-cyan-blue);
		font-size: 0.65rem;
		font-variant: small-caps;
		margin-bottom:0.7rem;
	}