@import "node_modules/primeflex/src/_variables";

:root {
    //Admin
    --Admin-Header-Fond : #004c72;
}

.header {
    width: 100%;
    height:130px;
    align-items:center;
    background: var(--Header-Fond);
}

.header-admin {
    width:100%;
    align-items:center;
    padding-top: 10px;
    padding-bottom: 10px;
    background: var(--Admin-Header-Fond);
}

.logo img {
    height: 90px;
    cursor: pointer;
    margin-left: 5%;

    &:focus {
        outline: none;
    }
}

@media screen and (max-width: $sm) {
    .connexion {
        .p-button-label {
            display: none;
        }

        .p-button-icon-left {
            margin-right: 0;
        }
    }
}

/* -------------------------------------------------------- BOUTONS -------------------------------------------------------- */
.row-buttons {
    text-align: center;
    display: flex;
}

/* ----- Bouton Connexion / Déconnexion ----- */
.connexion {
    border-radius: 0.25rem;
    background: rgb(246, 137, 45);
    white-space: nowrap;
    outline: none;
    font-family: var(--font-family);
    margin-left: 5px;

    .p-button {
        display: inline-block;
        background: linear-gradient(0deg,
                rgba(246, 137, 45, 1) 0%,
                rgba(246, 137, 45, 1) 50%,
                rgba(250, 178, 116, 1) 100%);
        border: none;

        &:hover {
            background: linear-gradient(0deg,
                    rgba(216, 102, 10, 1) 0%,
                    rgba(216, 102, 10, 1) 50%,
                    rgba(246, 137, 45, 1) 100%);
        }
    }

    .p-button-label {
        font-weight: bold;
    }
}

/* ----- Bouton "Sésame" ----- */
.client {
    .p-button {
        display: inline-block;
        width: 100%;
        background: linear-gradient(180deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(187, 225, 244, 1) 100%);
        border: none;

        &:hover {
            background: linear-gradient(0deg, rgba(235, 235, 235, 1) 40%, rgba(187, 225, 244, 1) 100%);
        }
    }

    .p-button-label {
        color: #004c72;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: bold;
        font-size: small;
    }
}

/* ----- Bouton "Administration" ----- */
.administration {
    .p-button {
        width: 100%;
        padding: 10px;
        background: linear-gradient(0deg,
                rgba(18, 61, 105) 0%,
                rgba(18, 61, 105) 50%,
                rgba(27, 91, 156, 1) 100%);
        border: none;

        &:hover {
            background: linear-gradient(0deg,
                    rgba(27, 91, 156, 1) 0%,
                    rgba(27, 91, 156, 1) 50%,
                    rgba(18, 61, 105) 100%);
        }
    }

    .p-button-label {
        font-weight: bold;
        font-size: smaller;
    }
}

/* ----- Bouton "Flash info" ----- */
.buttonFlash {
    cursor: pointer;
    text-align: center;
    padding: 5px 0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 100%;
    background-color: #E4304D;
    font-weight: 500;
    font-family: var(--font-family);
    color: #fff;
    border: none;
    text-transform: uppercase;
    font-size: 0.8rem;
    letter-spacing: 0.5rem;
    transition: all 0.5s ease-out;
}

.buttonFlash:hover {
    background-color: #b6273f !important;
}

/* -------------------------------------------------------- FIN BOUTONS -------------------------------------------------------- */

/* ----- Style nom prénom user connecté ----- */
.user {
    margin-left: 5px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: small;
    margin-bottom: 5px;
    color: var(--Header-Texte);
}

.user-admin {
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    font-size: small;
    margin-bottom: 5px;
}


/* ----- Style barre de recherche ----- */
.recherche {
    & * {
        font-size: 0.8rem;
    }

    & .p-dropdown {
        background: #efefef;
        color: #9B9B9B;
        border: none;
        padding: 2px;
        border-radius: 30px;
    }

    & .p-dropdown .p-dropdown-label.p-placeholder {
        color: #9B9B9B;
    }

    & .p-dropdown-trigger {
        align-items: center;
    }

    & .p-dropdown .p-dropdown-panel {
        border-radius: 15px;
    }

    & input {
        border-radius: 30px;
        font-family: var(--font-family);
        border: none;
        background: #efefef;
        color: #9B9B9B;
        padding: 10px 40px;
        width: 100%;
        height: auto;
    }

    & .icon-search::before {
        position: absolute;
        margin-left: 10px;
        margin-top: 5px;
    }

    & .pi-chevron-down::before {
        margin-left: 20%;
    }

    & input::placeholder {
        color: #9B9B9B;
    }

    & .icon-search::before {
        color: #9B9B9B;
        font-size: 1.25rem;
    }
}

.notification {
    --font-size: 2rem;
    color: var(--Header-Texte);
    cursor: pointer;
}

p-divider {
    display: flex;
    color: var(--Header-Texte);
}

.p-divider.p-divider-vertical:before {
    border-width: thin;
}

.p-divider.p-divider-vertical.p-divider-center {
    align-self: center;
    min-height: 80%;
}

/*-------------- Style flash info déroulé ---------------*/
.flashInfoAcces{
    position:absolute;
    width:100%;
    z-index:999;
    top:0;
    left:0;

    .bouton{
        margin-left: 33.3333%;
        width: 33.3333% !important;
    }
}

.flashInfo{
    padding:0;
    border:0;
    margin-left: 33.3333%;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.4);
    cursor: pointer;

    .image{
        position: relative;
        float: left;
        width: 25%;
        overflow: hidden;
    }

    .texteImage{
        position: relative;
        float: left;
        width: 75%;
        padding: 0px 10px;
    }

    .texte{
        position: relative;
        float: left;
        width: 100%;
        padding: 0px 10px;
    }

    & img {
        width:100%;
    }

    & p {
        text-align: justify;
    }
}

.p-sidebar-top{
    height:auto !important;
    width: 33.3333% !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}